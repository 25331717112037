"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _lodash = require("lodash");

var _ = require("..");

var _default = function _default(_ref) {
  var name = _ref.name,
      fields = _ref.fields,
      _ref$itemFields = _ref.itemFields,
      itemFields = _ref$itemFields === void 0 ? fields : _ref$itemFields;
  var capName = (0, _lodash.upperFirst)(name);
  return "\n    type ".concat(capName, " {\n      ").concat((0, _.makeFieldSchema)(itemFields), "\n    }\n  ");
};

exports["default"] = _default;