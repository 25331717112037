"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _lodash = require("lodash");

var _mutationArgs = _interopRequireDefault(require("./mutationArgs"));

var _default = function _default(_ref) {
  var name = _ref.name,
      fields = _ref.fields;
  var capName = (0, _lodash.upperFirst)(name);
  var id = fields.id,
      otherFields = (0, _objectWithoutProperties2["default"])(fields, ["id"]);
  return "\n    create".concat(capName, "(").concat((0, _mutationArgs["default"])({
    name: name,
    fields: otherFields
  }), "): ").concat(capName, "\n    update").concat(capName, "(").concat((0, _mutationArgs["default"])({
    name: name,
    fields: fields
  }), "): ").concat(capName, "\n    delete").concat(capName, "(").concat((0, _mutationArgs["default"])({
    name: name,
    fields: {
      id: id
    }
  }), "): ").concat(capName, "\n  ");
};

exports["default"] = _default;