"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  name: 'personAddressGroup',
  fields: {
    billing: 'PersonAddress!',
    shipping: 'PersonAddress!'
  }
};
exports["default"] = _default;