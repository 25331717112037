import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

import styled, { css } from 'styled-components';
import { legacySwappableCss } from '../../../theme';
export var GroupContainer = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  margin: ", ";\n"])), function (p) {
  return p.theme.spacing(2);
});
export var GroupLabel = styled.span(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  color: rgb(51, 51, 51);\n  font-size: ", ";\n  font-family: Lato-Bold, sans-serif;\n  font-weight: bold;\n"])), function (p) {
  return p.theme.spacing(2);
});
export var HelperText = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", "\n  color: ", ";\n  font-size: 14px;\n  font-family: Lato-Regular, sans-serif;\n"])), legacySwappableCss(css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n      margin: ", ";\n    "])), function (p) {
  return p.theme.spacing(0.5, 0, 0, 0);
}), css(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n      margin: ", ";\n    "])), function (p) {
  return p.theme.spacing(0.5, 0, 0, 2);
})), function (_ref) {
  var hasError = _ref.hasError,
      theme = _ref.theme;
  if (hasError) return theme.danger;
  return theme.grey3;
});
export var GroupHelperText = styled(HelperText)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  margin-bottom: 20px;\n"])));