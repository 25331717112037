"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _send = _interopRequireDefault(require("../utils/send"));

var mapItem = function mapItem(_ref) {
  var id = _ref.id,
      quantity = _ref.quantity;
  return {
    id: id,
    quantity: quantity
  };
};

var purchase = function purchase(_ref2) {
  var id = _ref2.id,
      affiliation = _ref2.affiliation,
      subtotal = _ref2.subtotal,
      tax = _ref2.tax,
      shipping = _ref2.shipping,
      total = _ref2.total,
      _ref2$items = _ref2.items,
      items = _ref2$items === void 0 ? [] : _ref2$items,
      _ref2$currency = _ref2.currency,
      currency = _ref2$currency === void 0 ? 'USD' : _ref2$currency;
  return (0, _send["default"])('track', 'Purchase', {
    value: subtotal,
    currency: currency,
    contents: items.map(mapItem),
    content_type: 'product'
  });
};

var _default = purchase;
exports["default"] = _default;