"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _lodash = require("lodash");

var _pluralize = require("pluralize");

var _fields = _interopRequireDefault(require("./fields"));

var _default = function _default(_ref) {
  var name = _ref.name,
      fields = _ref.fields,
      _ref$queryFields = _ref.queryFields,
      queryFields = _ref$queryFields === void 0 ? fields : _ref$queryFields;
  var capName = (0, _lodash.upperFirst)(name);
  var id = queryFields.id,
      otherFields = (0, _objectWithoutProperties2["default"])(queryFields, ["id"]);
  return "\n    ".concat(name, "(id: ID!): ").concat(capName, "\n    ").concat((0, _pluralize.plural)(name), "(").concat((0, _fields["default"])(otherFields), "): [").concat(capName, "]\n  ");
};

exports["default"] = _default;