import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9;

import styled from 'styled-components';
export var DisplayExtraLarge = styled.h1(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  color: ", ";\n  ", ";\n"])), function (p) {
  return p.brand ? p.theme[p.theme.brand] : p.theme.text;
}, function (p) {
  return p.theme.fontCss('displayExtraLarge');
});
export var DisplayLarge = styled.h2(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  color: ", ";\n  ", ";\n"])), function (p) {
  return p.brand ? p.theme[p.theme.brand] : p.theme.text;
}, function (p) {
  return p.theme.fontCss('displayLarge');
});
export var DisplayMedium = styled.h3(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  color: ", ";\n  ", ";\n  font-weight: ", ";\n"])), function (p) {
  return p.brand ? p.theme[p.theme.brand] : p.theme.text;
}, function (p) {
  return p.theme.fontCss('displayMedium');
}, function (p) {
  return p.bold ? p.theme.fontWeightBold : p.theme.fontWeightNormal;
});
export var DisplaySmall = styled.h4(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  color: ", ";\n  ", ";\n  font-weight: ", ";\n"])), function (p) {
  return p.brand ? p.theme[p.theme.brand] : p.theme.text;
}, function (p) {
  return p.theme.fontCss('displaySmall');
}, function (p) {
  return p.bold ? p.theme.fontWeightBold : p.theme.fontWeightNormal;
});
export var Heading = styled.h5(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  color: ", ";\n  ", ";\n"])), function (p) {
  return p.brand ? p.theme[p.theme.brand] : p.theme.text;
}, function (p) {
  return p.theme.fontCss('heading');
});
export var HeadingSmall = styled.h6(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  color: ", ";\n  ", ";\n  font-weight: ", ";\n"])), function (p) {
  return p.brand ? p.theme[p.theme.brand] : p.theme.text;
}, function (p) {
  return p.theme.fontCss('headingSmall');
}, function (p) {
  return p.bold ? p.theme.fontWeightBold : p.theme.fontWeightNormal;
});
export var SubHeading = styled.p(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  color: ", ";\n  ", ";\n"])), function (p) {
  return p.brand ? p.theme[p.theme.brand] : p.theme.text;
}, function (p) {
  return p.theme.fontCss('subHeading');
});
export var Body = styled.p(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  color: ", ";\n  ", ";\n"])), function (p) {
  return p.brand ? p.theme[p.theme.brand] : p.theme.text;
}, function (p) {
  return p.theme.fontCss('body');
});
export var Caption = styled.small(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  color: ", ";\n  ", ";\n"])), function (p) {
  return p.brand ? p.theme[p.theme.brand] : p.theme.text;
}, function (p) {
  return p.theme.fontCss('caption');
});