"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _pluralize = require("pluralize");

var _lodash = require("lodash");

var testData = {
  widget: {
    '1': {
      id: 1,
      name: 'first'
    },
    '2': {
      id: 2,
      name: 'second'
    }
  }
};

var _default = function _default(_ref) {
  var _ref3;

  var name = _ref.name;
  var data = testData[name];
  return _ref3 = {}, (0, _defineProperty2["default"])(_ref3, name, function (a) {
    var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        id = _ref2.id;

    return data["".concat(id)];
  }), (0, _defineProperty2["default"])(_ref3, (0, _pluralize.plural)(name), function (a, filters) {
    return (0, _lodash.filter)(Object.values(data), filters);
  }), _ref3;
};

exports["default"] = _default;