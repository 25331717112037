import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2;

import styled from 'styled-components';

var generateGridTemplateColumns = function generateGridTemplateColumns(cols) {
  return new Array(cols).fill('1fr').join(' ');
};

var Grid = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-columns: ", ";\n  grid-gap: ", ";\n  grid-auto-rows: minmax(100px, auto);\n  margin: 0 ", ";\n  @media (max-width: 600px) {\n    grid-template-columns: ", ";\n    grid-gap: ", ";\n    margin: 0 ", ";\n  }\n"])), generateGridTemplateColumns(8), function (p) {
  return p.theme.spacing(3);
}, function (p) {
  return p.theme.spacing(3);
}, generateGridTemplateColumns(4), function (p) {
  return p.theme.spacing(2);
}, function (p) {
  return p.theme.spacing(2);
});
export var GridItem = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  grid-column: span ", ";\n  background-color: orange;\n"])), function (_ref) {
  var _ref$numCols = _ref.numCols,
      numCols = _ref$numCols === void 0 ? 1 : _ref$numCols;
  return numCols;
});
export default Grid;