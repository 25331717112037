"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  name: 'person',
  fields: {
    id: 'ID!',
    addresses: 'PersonAddressGroup!',
    email: 'NonEmptyString!'
  }
};
exports["default"] = _default;