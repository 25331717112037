"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _getInputTypeName = _interopRequireDefault(require("../../lib/getInputTypeName"));

var _default = function _default(_ref) {
  var fields = _ref.fields;
  return Object.entries(fields).map(function (_ref2) {
    var _ref3 = (0, _slicedToArray2["default"])(_ref2, 2),
        fieldName = _ref3[0],
        type = _ref3[1];

    return "".concat(fieldName, ": ").concat((0, _getInputTypeName["default"])(type));
  }).join('\n');
};

exports["default"] = _default;