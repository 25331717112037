import React from 'react'
import styled from 'styled-components'
import { DescriptionCell, QtyCell, PriceCell } from './StandardRow'
import { HeadingCell, TableRow } from './parts'

const HEADING_ROW_COLUMNBS = [
  () => <DescriptionCell as={HeadingCell}>Description</DescriptionCell>,
  () => <QtyCell as={HeadingCell}>Qty</QtyCell>,
  () => <PriceCell as={HeadingCell}>Price</PriceCell>,
]

const HeadingRow = styled(TableRow).attrs({ columns: HEADING_ROW_COLUMNBS })`
  padding-bottom: 5px;
  border-bottom: 1px solid #aaa;
`

export default HeadingRow
