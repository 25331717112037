"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _graphql = require("graphql");

var _language = require("graphql/language");

var _lodash = require("lodash");

var _default = new _graphql.GraphQLScalarType({
  name: 'NonZeroFloat',
  description: 'Date custom scalar type',
  parseValue: function parseValue(value) {
    return value;
  },
  serialize: function serialize(value) {
    return value;
  },
  parseLiteral: function parseLiteral(ast) {
    if (ast.kind === _language.Kind.INT) {
      return parseInt(ast.value, 10) || undefined;
    } else if (ast.kind === _language.Kind.FLOAT) {
      return parseFloat(ast.value) || undefined;
    }

    return undefined;
  }
});

exports["default"] = _default;