import React, { useEffect } from 'react'
import { AddressSection } from '@foundation-lib/form-ui'
import {
  TextInput,
  SelectInput,
  Option,
  CheckboxInput,
  useField,
} from '@foundation-lib/formik-form'

export default () => {
  const [{ value: useBillingAddressForShipping }] = useField(
    'useBillingAddressForShipping',
  )
  const [{ value: billingAddress }] = useField('addresses.billing')
  const [
    { value: shippingAddress },
    ,
    { setValue: setShippingAddress },
  ] = useField('addresses.shipping')

  useEffect(() => {
    if (useBillingAddressForShipping) setShippingAddress(billingAddress)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useBillingAddressForShipping, billingAddress])

  return (
    <>
      <h2>Billing info</h2>
      <AddressSection
        data={billingAddress}
        TextField={TextInput}
        SelectField={SelectInput}
        OptionComponent={Option}
        formPath="addresses.billing"
        enableEmail
      />

      <div style={{ marginTop: '15px', marginBottom: '20px' }}>
        <CheckboxInput
          color="secondary"
          name="useBillingAddressForShipping"
          label="Use this address for shipping"
        />
      </div>

      {!useBillingAddressForShipping && (
        <>
          <h2>Shipping info</h2>
          <AddressSection
            data={shippingAddress}
            TextField={TextInput}
            SelectField={SelectInput}
            OptionComponent={Option}
            formPath="addresses.shipping"
          />
          <div style={{ height: '35px' }}>&nbsp;</div>
        </>
      )}
    </>
  )
}
