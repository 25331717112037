"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var useWindowStripe = function useWindowStripe(apiKey) {
  var _useState = (0, _react.useState)(null),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      stripe = _useState2[0],
      setStripe = _useState2[1];

  (0, _react.useEffect)(function () {
    setStripe(typeof window !== "undefined" && window.Stripe(apiKey));
  }, []);
  return stripe;
};

var _default = useWindowStripe;
exports["default"] = _default;