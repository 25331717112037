"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var _useField3 = _interopRequireDefault(require("./useField"));

var _useFormContext2 = _interopRequireDefault(require("./useFormContext"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var useFieldCustomized = function useFieldCustomized(p) {
  var _useField = (0, _useField3["default"])(p.name),
      _useField2 = (0, _slicedToArray2["default"])(_useField, 3),
      field = _useField2[0],
      meta = _useField2[1],
      setValue = _useField2[2].setValue;

  var _useFormContext = (0, _useFormContext2["default"])(),
      submitCount = _useFormContext.submitCount,
      isSubmitting = _useFormContext.isSubmitting;

  var formError = meta.error && (meta.touched || submitCount);
  var hasError = p.hasError || formError;
  (0, _react.useEffect)(function () {
    if (p.defaultValue && !field.value) setValue(p.defaultValue); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [p.defaultValue]);
  return _objectSpread(_objectSpread({}, field), {}, {
    hasError: hasError,
    helperText: hasError && (p.helperText || meta.error) || '',
    onChange: function onChange(e) {
      field.onChange(e);
      if (p.onChange) p.onChange(e);
    },
    onBlur: function onBlur(e) {
      field.onBlur(e);
      if (p.onBlur) p.onBlur(e);
    },
    disabled: isSubmitting
  });
};

var _default = useFieldCustomized;
exports["default"] = _default;