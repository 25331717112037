import React from 'react'
import styled from 'styled-components'
import { TableRow, HeadingCell } from './parts'
import { PriceCell } from './StandardRow'

const TotalRow = styled(TableRow).attrs({
  columns: [() => <HeadingCell>Total</HeadingCell>, PriceCell],
})`
  padding-top: 5px;
  border-top: 1px solid #aaa;
`

export default TotalRow
