import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject;

import styled from 'styled-components';
var PageSeparator = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  align-items: center;\n  display: inline-flex;\n  font-size: 16px;\n  height: ", ";\n  justify-content: center;\n  min-width: ", ";\n  margin: 0 3px;\n  padding: 0 6px;\n  text-align: center;\n"])), function (p) {
  return p.theme.spacing(4);
}, function (p) {
  return p.theme.spacing(4);
});
export default PageSeparator;