"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "gtm", {
  enumerable: true,
  get: function get() {
    return _gtm["default"];
  }
});
Object.defineProperty(exports, "gtmFbpixel", {
  enumerable: true,
  get: function get() {
    return _gtm.fbpixel;
  }
});
Object.defineProperty(exports, "fbpixel", {
  enumerable: true,
  get: function get() {
    return _fbpixel["default"];
  }
});
Object.defineProperty(exports, "createAnalyticsClient", {
  enumerable: true,
  get: function get() {
    return _createAnalyticsClient["default"];
  }
});

var _gtm = _interopRequireWildcard(require("./providers/gtm"));

var _fbpixel = _interopRequireDefault(require("./providers/fbpixel"));

var _createAnalyticsClient = _interopRequireDefault(require("./utils/createAnalyticsClient"));