import { useEffect, useRef } from 'react';

var useDynamicBodyEl = function useDynamicBodyEl() {
  var idRef = useRef("portal-".concat(Date.now()).concat(Math.floor(Math.random() * Math.floor(100))));
  var elRef = useRef(typeof document !== "undefined" && (document.getElementById(idRef.current) || document.createElement('div')));
  var isDynamicRef = useRef(!elRef.current.parentElement);
  useEffect(function () {
    var isDynamic = isDynamicRef.current;
    var el = elRef.current;

    if (isDynamic) {
      // eslint-disable-next-line fp/no-mutation
      el.id = idRef.current;
      el.setAttribute('style', 'z-index: 2000; position: absolute; top: 0px; left: 0px;');
      if (typeof document !== "undefined") document.body.appendChild(el);
    }

    return function () {
      if (isDynamic && el.parentElement) {
        el.parentElement.removeChild(el);
      }
    };
  }, []);
  return elRef.current;
};

export default useDynamicBodyEl;