"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var decodeItem = function decodeItem(_ref) {
  var id = _ref.id,
      name = _ref.name,
      price = _ref.price,
      brand = _ref.brand,
      category = _ref.category,
      variant = _ref.variant,
      position = _ref.position,
      quantity = _ref.quantity;
  return {
    id: id,
    label: name,
    price: parseFloat(price || 0),
    brand: brand,
    category: category,
    variant: variant,
    position: position,
    quantity: quantity
  };
};

var decodePurchase = function decodePurchase(_ref2) {
  var _ref2$ecommerce$purch = _ref2.ecommerce.purchase,
      _ref2$ecommerce$purch2 = _ref2$ecommerce$purch.actionField,
      id = _ref2$ecommerce$purch2.id,
      affiliation = _ref2$ecommerce$purch2.affiliation,
      revenue = _ref2$ecommerce$purch2.revenue,
      tax = _ref2$ecommerce$purch2.tax,
      shipping = _ref2$ecommerce$purch2.shipping,
      products = _ref2$ecommerce$purch.products;
  return {
    id: id,
    affiliation: affiliation,
    subtotal: (revenue || 0) - (tax || 0) - (shipping || 0),
    tax: tax,
    shipping: shipping,
    total: revenue,
    items: products.map(decodeItem)
  };
};

var _default = decodePurchase;
exports["default"] = _default;