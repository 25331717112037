import React from 'react'
import styled from 'styled-components'
import { trimStart, capitalize } from 'lodash'
import { faTimesCircle } from '@foundation-lib/ui/icons/solid'
import { Icon } from '@foundation-lib/ui'
import { TableRow, TableCell } from './parts'
import useCartItemList from '../../data/useCartItemList'
import { useSettingsContext } from '../../../../SettingsContext'

export const DescriptionCell = styled(({ className, ...other }) => {
  const { ProductLink, itemTypes = [] } = useSettingsContext()
  const editionLabel =
    itemTypes.find(({ name }) => name === other.type)?.label ||
    capitalize(other.type)

  const label = (
    <>
      {other.label}
      {editionLabel && ` (${editionLabel})`}
      {other.preorder && ` - Preorder`}
    </>
  )

  return (
    <TableCell className={className}>
      {other.slug && ProductLink ? (
        <ProductLink {...other}>{label}</ProductLink>
      ) : (
        label
      )}
    </TableCell>
  )
})`
  flex: 1 1 auto;
`

export const PriceCell = styled(TableCell).attrs(({ price, ...other }) => ({
  children: price ? `$${parseFloat(price).toFixed(2)}` : null,
  ...other,
}))`
  text-align: right;
  width: 25%;
`

const RemoveIcon = styled(Icon).attrs({ icon: faTimesCircle })`
  margin-left: ${p => (p.hideQty ? '47px' : '7px')};
  font-size: 20px;
  cursor: pointer;
`

export const QtyCell = styled(
  ({ label, name, price, qty, type, hideQty, ...other }) => {
    const sendCartItemListAction = useCartItemList(s => s.action)
    const item = { label, name, price, qty, type }

    const changeQuantity = newQty =>
      sendCartItemListAction({
        action: 'change_quantity',
        item: { ...item, qty: newQty },
      })

    const handleChange = e => {
      const strValue = trimStart(e.target.value, '0')

      if (strValue === '') {
        changeQuantity('')
        return
      }

      const numberValue = parseInt(strValue, 10)

      if (strValue === `${numberValue}`) {
        changeQuantity(numberValue)
      }
    }

    return (
      <TableCell {...other}>
        {!hideQty && (
          <input
            style={{ width: '40px' }}
            value={qty}
            onChange={handleChange}
          />
        )}
        <RemoveIcon
          hideQty={hideQty}
          onClick={() => sendCartItemListAction({ action: 'remove', item })}
        />
      </TableCell>
    )
  },
)`
  width: 71px;
  display: flex;
  align-items: center;
`

const StandardRow = props => (
  <TableRow columns={[DescriptionCell, QtyCell, PriceCell]} {...props} />
)

export default StandardRow
