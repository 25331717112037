import { useMutation } from '@apollo/react-hooks'
import { usePromise } from '@foundation-lib/promise'
import { createToken, useStripe } from '@foundation-lib/stripe'
import CREATE_PURCHASE from '../data/mutations/createPurchase'
import analyticsClient from './analyticsClient'
import mapPurchaseDetails from './mapPurchaseDetails'

const convertItemToPurchaseAnalytic = ({ name, quantity, unitPrice }) => ({
  id: name,
  label: name,
  price: unitPrice,
  quantity,
})

const convertFormDataToPurchaseAnalytic = ({
  id,
  subtotal,
  tax = 0,
  shippingCost,
  total,
  items,
}) => ({
  id,
  subtotal,
  tax,
  shipping: shippingCost,
  total,
  items: items.map(convertItemToPurchaseAnalytic),
})

const useCreatePurchase = () => {
  const [createPurchase, { error, data }] = useMutation(CREATE_PURCHASE, {
    ignoreResults: false,
  })
  const stripe = useStripe()
  const req = usePromise(
    async ({ imperativeArgs: [formData, cartData] }) => {
      const token = await createToken(stripe, {
        addresses: formData?.addresses,
      })
      const purchase = mapPurchaseDetails(formData, cartData)
      const result = await createPurchase({
        variables: {
          ...purchase,
          stripeToken: token.id,
        },
      })

      const purchaseId = result?.data?.createPurchase?.id

      if (purchaseId) {
        analyticsClient.purchase(
          convertFormDataToPurchaseAnalytic({ ...purchase, id: purchaseId }),
        )
      }
    },
    [stripe],
    {
      lazy: true,
    },
  )

  return {
    ...req,
    err: req.err || error,
    response: data?.createPurchase,
  }
}

export default useCreatePurchase
