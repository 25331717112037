import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { range, limit } from './ranges';

var visiblePageNumbers = function visiblePageNumbers(page, options) {
  var _options$siblingStret = options.siblingStretchLength,
      siblingStretchLength = _options$siblingStret === void 0 ? 1 : _options$siblingStret,
      _options$edgeStretchL = options.edgeStretchLength,
      edgeStretchLength = _options$edgeStretchL === void 0 ? 0 : _options$edgeStretchL,
      count = options.count;
  var currentPage = limit(page, {
    min: 1,
    max: count
  });
  var withinPage = {
    min: 1,
    max: count
  };

  var limitToPageRange = function limitToPageRange(value) {
    return limit(value, withinPage);
  };

  var boundedRange = function boundedRange(_ref) {
    var from = _ref.from,
        to = _ref.to;
    return range({
      from: limitToPageRange(from),
      to: limitToPageRange(to)
    });
  };

  var leftAlignedPageRange = function leftAlignedPageRange(middleRangeWidth) {
    var from = edgeStretchLength + 2;
    var to = from + middleRangeWidth;
    return boundedRange({
      from: from,
      to: to
    });
  };

  var rightAlignedPageRange = function rightAlignedPageRange(middleRangeWidth) {
    var to = count - edgeStretchLength - 1;
    var from = to - middleRangeWidth;
    return boundedRange({
      from: from,
      to: to
    });
  };

  var centralPageRange = function centralPageRange() {
    return boundedRange({
      from: currentPage - siblingStretchLength,
      to: currentPage + siblingStretchLength
    });
  };

  var lhsPageRange = function lhsPageRange() {
    return range({
      from: 1,
      to: limitToPageRange(1 + edgeStretchLength)
    });
  };

  var rhsPageRange = function rhsPageRange() {
    return range({
      from: limitToPageRange(count - edgeStretchLength),
      to: count
    });
  };

  var middlePageRange = function middlePageRange() {
    var centerItemPosition = edgeStretchLength + siblingStretchLength + 2;
    var middleRangeWidth = 1 + siblingStretchLength * 2;

    if (currentPage <= centerItemPosition + 1) {
      return leftAlignedPageRange(middleRangeWidth);
    } else if (currentPage >= count - centerItemPosition) {
      return rightAlignedPageRange(middleRangeWidth);
    }

    return centralPageRange();
  };

  var pageNumberSet = new Set([].concat(_toConsumableArray(lhsPageRange()), _toConsumableArray(middlePageRange()), _toConsumableArray(rhsPageRange())));
  return Array.from(pageNumberSet);
};

export default visiblePageNumbers;