import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _templateObject;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css } from 'styled-components';
import upperFirst from 'lodash/upperFirst';
export var spacing = function spacing() {
  for (var _len = arguments.length, nums = new Array(_len), _key = 0; _key < _len; _key++) {
    nums[_key] = arguments[_key];
  }

  return nums.map(function (num) {
    return "".concat(num * 8, "px");
  }).join(' ');
};
var multipliers = {
  body: [1, 1],
  caption: [0.75, 2 / 3],
  displayExtraLarge: [21 / 8, 11 / 6],
  displayLarge: [7 / 4, 4 / 3],
  displayMedium: [13 / 8, 4 / 3],
  displaySmall: [1.2, 1],
  heading: [1, 1],
  headingSmall: [7 / 8, 5 / 6],
  subHeading: [0.75, 2 / 3]
};
var fontVars = {
  fontFamilyBase: 'font-family-sans-serif',
  fontFamilySansSerif: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  fontFamilyMonospace: 'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
  fontSizeBase: '1rem',
  fontWeightNormal: '400',
  fontWeightBold: '700',
  lineHeightBase: '1.5rem',
  navbarHeight: 'calc(21 / 4 * 1rem)',
  remSize: spacing(2)
};

var fontSizes = function fontSizes() {
  return Object.keys(multipliers).reduce(function (sizes, size) {
    return _objectSpread(_objectSpread({}, sizes), {}, _defineProperty({}, "fontSize".concat(upperFirst(size)), "calc(".concat(multipliers[size][0], " * 1rem)")));
  }, {});
}; // ♫♪ It's just another ma-gic num-ber ♫♪ <-- https://youtu.be/SsmVgoXDq2w
// But really, it's just the W3C guidelines from here:
//   https://www.w3.org/TR/WCAG20/#relativeluminancedef


var highContrast = function highContrast(darkColor, lightColor) {
  return function (brand) {
    var color = brand.charAt(0) === '#' ? brand.substring(1, 7) : brand;
    var r = parseInt(color.substring(0, 2), 16); // get red channel

    var g = parseInt(color.substring(2, 4), 16); // get green channel

    var b = parseInt(color.substring(4, 6), 16); // get blue channel

    var channels = [r / 255, g / 255, b / 255]; // fractional intensities of channels
    // See link for magic numbers and algorithm explanation

    var brightness = channels.map(function (channel) {
      return channel <= 0.03928 ? channel / 12.92 : Math.pow((channel + 0.055) / 1.055, 2.4);
    });
    var relativeLuminance = 0.2126 * brightness[0] + 0.7152 * brightness[1] + 0.0722 * brightness[2]; // W3C recommends (lighter + 0.05) / (darker + 0.05)
    // So we compare against black and white and use the one that's greater
    // (brand / 0.05) / (black / 0.05) > (white / 0.05) / (brand / 0.05)
    // where white is defined as 1.0 and black is defined as 0.0
    // Put on your nerd hat and sprinkle on some algebra and here we are

    return relativeLuminance > Math.sqrt(1.05 * 0.05) - 0.05 ? darkColor : lightColor;
  };
}; // For legacy apps, the contrast we have is not ideal, therefore, we need to
// override this function specifically to give different results


var legacyHighContrast = function legacyHighContrast(darkColor, lightColor) {
  return function (brand) {
    var color = brand.charAt(0) === '#' ? brand.substring(1, 7) : brand;
    var r = parseInt(color.substring(0, 2), 16); // get red channel

    var g = parseInt(color.substring(2, 4), 16); // get green channel

    var b = parseInt(color.substring(4, 6), 16); // get blue channel

    var channels = [r / 255, g / 255, b / 255]; // fractional intensities of channels

    var brightness = channels.map(function (channel) {
      return channel <= 0.03928 ? channel / 12.92 : Math.pow((channel + 0.055) / 1.055, 2.4);
    });
    var relativeLuminance = 0.2126 * brightness[0] + 0.7152 * brightness[1] + 0.0722 * brightness[2]; // The threshold is all we have to fiddle with to get "worse" results

    return relativeLuminance > 0.6 ? darkColor : lightColor;
  };
};

var fontCss = function fontCss() {
  return function (size) {
    var bold = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    return css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  font-size: calc(", " * 1rem);\n  font-weight: ", ";\n  line-height: calc(", " * ", ");\n  margin: 0;\n"])), multipliers[size][0], bold ? fontVars.fontWeightBold : fontVars.fontWeightNormal, multipliers[size][1], fontVars.lineHeightBase);
  };
};

export var fonts = function fonts(darkColor, lightColor) {
  return _objectSpread(_objectSpread(_objectSpread({}, fontVars), fontSizes()), {}, {
    fontCss: fontCss(),
    highContrast: highContrast(darkColor, lightColor),
    spacing: spacing
  });
};
export var legacyFonts = function legacyFonts(darkColor, lightColor) {
  return _objectSpread(_objectSpread({}, fonts(darkColor, lightColor)), {}, {
    fontCss: fontCss(),
    highContrast: legacyHighContrast(darkColor, lightColor)
  });
};