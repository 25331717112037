"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _purchase = _interopRequireDefault(require("./events/purchase"));

var _send = _interopRequireDefault(require("./utils/send"));

var fbpixel = {
  purchase: _purchase["default"],
  send: _send["default"]
};
var _default = fbpixel;
exports["default"] = _default;