import React from 'react'
import styled from 'styled-components'

export const ItemsTable = styled.table.attrs({ border: 0 })`
  width: 100%;
  border-collapse: collapse;
`

export const TableRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const TableCell = styled.td``

export const TableRow = ({ className, data, columns }) => (
  <TableRowContainer className={className}>
    {columns.map(Column => (
      <Column {...data} />
    ))}
  </TableRowContainer>
)

export const HeadingCell = styled(TableCell)`
  font-weight: bold;
`
