"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var eventNames = ['purchase'];

var createAnalyticsClient = function createAnalyticsClient(_ref) {
  var _ref$providers = _ref.providers,
      providers = _ref$providers === void 0 ? [] : _ref$providers;
  return Object.fromEntries(eventNames.map(function (eventName) {
    return [eventName, function () {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      return providers.forEach(function (provider) {
        return provider[eventName].apply(provider, args);
      });
    }];
  }));
};

var _default = createAnalyticsClient;
exports["default"] = _default;