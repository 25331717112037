import { useContext } from 'react';
import PaginationContext from '../data/PaginationContext';
import useNewPagination from './useNewPagination';

var usePagination = function usePagination() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var pagination = useNewPagination(options);
  var paginationContext = useContext(PaginationContext);
  return paginationContext || pagination;
};

export default usePagination;