import { sumBy } from 'lodash'
import useCartItemList from './useCartItemList'
import useShippingCalculator from './useShippingCalculator'

const useAllCartData = ({ shippingAddress, shippingService }) => {
  const { items, getSubtotal } = useCartItemList()
  const orderWeight = sumBy(
    items,
    ({ weightOz, qty }) => (weightOz || 0) * (qty || 1),
  )
  const shipping = useShippingCalculator({
    orderWeight,
    selectedService: shippingService,
    shippingAddress,
  })
  const subtotal = getSubtotal()
  const total = subtotal + shipping.cost
  return { total, subtotal, shipping, items }
}

export default useAllCartData
