import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject;

import styled from 'styled-components';
var MarginOffset = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin: ", ";\n  padding: 1px;\n"])), function (p) {
  return {
    none: '0px',
    medium: '-11px'
  }[p.margin || 'medium'];
});
export default MarginOffset;