import { compact, omit } from 'lodash'

const mapPurchaseDetails = (
  { addresses, ...otherFormData } = {},
  { shipping: { cost, service }, subtotal, total, items } = {},
) => ({
  ...otherFormData,
  person: {
    addresses: {
      billing: omit(addresses?.billing, 'email'),
      shipping: omit(addresses?.shipping, 'email'),
    },
    email: addresses?.billing?.email,
  },
  items: compact(
    items.map(({ name, price, qty, type, label }) => ({
      name,
      unitPrice: price,
      quantity: qty,
      type,
      label,
    })),
  ),
  shippingCost: cost,
  shippingService: service,
  subtotal,
  total,
})

export default mapPurchaseDetails
