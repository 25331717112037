import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2;

import styled, { css } from 'styled-components';
import Button from './Button';
import { legacySwappableCss } from '../../theme';
var StyledStepButton = styled(Button)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background: #ffffff;\n  ", "\n\n  ", "\n"])), legacySwappableCss('', css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n      border: 1px solid ", ";\n      box-shadow: ", ";\n      :hover {\n        box-shadow: ", ";\n      }\n    "])), function (p) {
  return p.theme.grey6;
}, function (p) {
  return p.theme.shadowSmallest;
}, function (p) {
  return p.theme.shadowSmall;
})), function (_ref) {
  var isHidden = _ref.isHidden;
  return isHidden && "\n    visibility: hidden;\n  ";
});
export default StyledStepButton;