var convertChildrenToItems = function convertChildrenToItems(children) {
  var childArray = Array.isArray(children) ? children : [children];
  return childArray.map(function (ch) {
    return ch.props && {
      label: ch.props.children,
      value: ch.props.value
    };
  }).filter(function (v) {
    return v;
  });
};

export default convertChildrenToItems;