"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _send = _interopRequireDefault(require("../utils/send"));

var mapItem = function mapItem(_ref) {
  var id = _ref.id,
      label = _ref.label,
      price = _ref.price,
      brand = _ref.brand,
      category = _ref.category,
      variant = _ref.variant,
      position = _ref.position,
      quantity = _ref.quantity;
  return {
    id: id,
    name: label,
    price: "".concat(price),
    brand: brand,
    category: category,
    variant: variant,
    position: position,
    quantity: quantity
  };
};

var purchase = function purchase(_ref2) {
  var id = _ref2.id,
      affiliation = _ref2.affiliation,
      subtotal = _ref2.subtotal,
      tax = _ref2.tax,
      shipping = _ref2.shipping,
      total = _ref2.total,
      _ref2$items = _ref2.items,
      items = _ref2$items === void 0 ? [] : _ref2$items;
  return (0, _send["default"])({
    event: 'purchase',
    ecommerce: {
      purchase: {
        actionField: {
          id: id,
          affiliation: affiliation,
          revenue: total,
          tax: tax,
          shipping: shipping
        },
        products: items.map(mapItem)
      }
    }
  });
};

var _default = purchase;
exports["default"] = _default;