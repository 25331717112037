"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  name: 'shippingRate',
  queryFields: {
    carrierCode: 'String!',
    state: 'String!',
    country: 'String!',
    zip: 'String!',
    weightOz: 'Float!'
  },
  itemFields: {
    serviceName: 'String',
    serviceCode: 'String',
    shipmentCost: 'Float',
    otherCost: 'Float'
  }
};
exports["default"] = _default;