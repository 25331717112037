import React from 'react'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { SettingsProvider } from '@foundations-of-grace-pub/checkout'
import { ProductLink } from 'src/features/product'
import { ApolloProvider } from '@apollo/react-hooks'
import { Theme } from '@foundation-lib/ui'
import itemTypes from './data/itemTypes'
import { theme } from './features/global'
import client from './apollo/client'

export default ({ element }) => (
  <ApolloProvider client={client}>
    <SettingsProvider value={{ itemTypes, ProductLink }} apolloClient={client}>
      <Theme theme={theme}>{element}</Theme>
    </SettingsProvider>
  </ApolloProvider>
)
