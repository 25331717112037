import { useEffect } from 'react'; // @ts-ignore

import { conformToMask, adjustCaretPosition } from 'text-mask-core';
import { changeInputValueByRef } from '../../shared/input';
import { isFunction } from 'lodash';

var markUnused = function markUnused() {
  var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  if (Object.values(opts).some(function (v) {
    return v;
  })) {
    // eslint-disable-next-line no-console
    console.warn("The following useMaskedInput options (originally available in the \"text-mask\" project) are currently unimplemented and will need follow-up work if they are needed in the future: ".concat(Object.keys(opts).join(', ')));
  }
};

var strNone = 'none';
var isAndroid = typeof navigator !== 'undefined' && /Android/i.test(navigator.userAgent);
var defer = typeof requestAnimationFrame !== 'undefined' ? requestAnimationFrame : setTimeout;

function safeSetSelection(element, selectionPosition) {
  if (typeof document !== "undefined" && document.activeElement === element) {
    if (isAndroid) {
      defer(function () {
        return element.setSelectionRange(selectionPosition, selectionPosition, strNone);
      }, 0);
    } else {
      element.setSelectionRange(selectionPosition, selectionPosition, strNone);
    }
  }
}

var parseMask = function parseMask(mask) {
  return mask ? mask.split('').map(function (c) {
    switch (c) {
      case 'A':
        return /[a-zA-Z]/;

      case '#':
        return /\d/;

      default:
        return c;
    }
  }) : undefined;
};

var useMaskedInput = function useMaskedInput(_ref) {
  var inputRef = _ref.inputRef,
      maskArrOrStr = _ref.mask,
      onChange = _ref.onChange,
      value = _ref.value,
      guide = _ref.guide,
      keepCharPositions = _ref.keepCharPositions,
      pipe = _ref.pipe,
      _ref$placeholderChar = _ref.placeholderChar,
      placeholderChar = _ref$placeholderChar === void 0 ? '_' : _ref$placeholderChar,
      showMask = _ref.showMask;
  markUnused({
    showMask: showMask,
    pipe: pipe,
    keepCharPositions: keepCharPositions,
    guide: guide
  });
  console.log(maskArrOrStr);
  var mask = Array.isArray(maskArrOrStr) || isFunction(maskArrOrStr) ? maskArrOrStr : parseMask(maskArrOrStr);
  var placeholder = conformToMask('', mask, {}).conformedValue;
  useEffect(function () {
    if (value) {
      var _conformToMask = conformToMask(value, mask, {}),
          conformedValue = _conformToMask.conformedValue;

      if (conformedValue !== value) {
        changeInputValueByRef(inputRef, conformedValue);
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, []);
  return function (e) {
    var _conformToMask2 = conformToMask(e.target.value, mask, {}),
        conformedValue = _conformToMask2.conformedValue;

    var currentCaretPosition = inputRef.current.selectionEnd;
    var opts = {
      previousConformedValue: value || '',
      conformedValue: conformedValue,
      currentCaretPosition: currentCaretPosition,
      rawValue: e.target.value,
      placeholderChar: placeholderChar,
      placeholder: placeholder
    };
    var newCaretPos = adjustCaretPosition(opts);
    console.log(newCaretPos, opts);
    changeInputValueByRef(inputRef, conformedValue);
    if (onChange) onChange(e);
    safeSetSelection(inputRef.current, newCaretPos);
  };
};

export default useMaskedInput;