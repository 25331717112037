"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var convertAddress = function convertAddress() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      line1 = _ref.line1,
      line2 = _ref.line2,
      city = _ref.city,
      state = _ref.state,
      zip = _ref.zip,
      country = _ref.country;

  return {
    address_line1: line1,
    address_line2: line2,
    address_city: city,
    address_state: state,
    address_zip: zip,
    address_country: country
  };
};

var _default = function _default() {
  var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref2$addresses = _ref2.addresses;

  _ref2$addresses = _ref2$addresses === void 0 ? {} : _ref2$addresses;
  var billing = _ref2$addresses.billing;

  var _ref3 = (billing === null || billing === void 0 ? void 0 : billing.name) || {},
      first = _ref3.first,
      last = _ref3.last;

  return _objectSpread(_objectSpread({}, convertAddress(billing)), {}, {
    name: "".concat(first, " ").concat(last)
  });
};

exports["default"] = _default;