import { without, sumBy, round } from 'lodash'
import create from 'zustand'

const KEY = 'cartItemList'

const doesItemMatch = ({ name, type }, itemToMatch) =>
  name === itemToMatch.name && (!itemToMatch.type || itemToMatch.type === type)

const findMatchingItem = (list, itemToMatch) =>
  list.find(listItem => doesItemMatch(listItem, itemToMatch))

const addItem = (list = [], newItem) => {
  const itemExists = findMatchingItem(list, newItem)

  if (itemExists)
    return list.map(listItem =>
      doesItemMatch(listItem, newItem)
        ? {
            ...listItem,
            ...(newItem.addToPrice
              ? {
                  price: listItem.price + newItem.price,
                }
              : {
                  qty: listItem.qty + newItem.qty,
                }),
          }
        : listItem,
    )

  return [...list, newItem]
}

const removeItem = (list = [], itemToRemove) =>
  without(list, findMatchingItem(list, itemToRemove))

const changeItemQuantity = (list, item) =>
  list.map(existingItem =>
    existingItem.name === item.name && existingItem.type === item.type
      ? {
          ...existingItem,
          qty: item.qty,
        }
      : existingItem,
  )

const getUpdatedList = (list, { action, item }) => {
  switch (action) {
    case 'add':
      return addItem(list, item)

    case 'remove':
      return removeItem(list, item)

    case 'change_quantity':
      return changeItemQuantity(list, item)

    case 'empty':
      return []

    default:
      return []
  }
}

const JSONParse = str => {
  if (!str) return []
  try {
    return JSON.parse(str)
  } catch (err) {
    return []
  }
}

const getSessionStorage = () =>
  typeof window !== `undefined`
    ? sessionStorage
    : { getItem: () => {}, setItem: () => {} }

const [useCartItemList] = create((set, get) => ({
  items: JSONParse(getSessionStorage().getItem(KEY)),
  action: ({ action, item, items }) => {
    const newItems =
      action === 'replace'
        ? items
        : getUpdatedList(get().items, { action, item })

    getSessionStorage().setItem(KEY, JSON.stringify(newItems))

    set({ items: newItems })
  },
  getSubtotal: () =>
    round(
      sumBy(get().items, ({ qty, price }) => qty * price),
      2,
    ),
  getItemCount: () => sumBy(get().items, 'qty'),
  getItem: item => findMatchingItem(get().items, item),
}))

export default useCartItemList
