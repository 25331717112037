import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3;

import styled, { css } from 'styled-components';
import { legacySwappableCss } from '../../theme';
var StyledButton = styled.button(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  align-items: center;\n  background: #ffffff;\n  border: 0;\n  ", "\n  display: inline-flex;\n  ", "\n  height: ", ";\n  justify-content: center;\n  min-width: ", ";\n  margin: 0 3px;\n  outline: 0;\n  padding: 0 6px;\n  text-align: center;\n  cursor: pointer;\n"])), legacySwappableCss(css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n      color: ", ";\n    "])), function (p) {
  return p.theme.grey2;
}), css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n      border-radius: 50%;\n    "])))), function (p) {
  return p.theme.fontCss('body');
}, function (p) {
  return p.theme.spacing(4);
}, function (p) {
  return p.theme.spacing(4);
});
export default StyledButton;