import { spacing } from './fonts';
export var shadows = function shadows(shadowColor) {
  return {
    shadowSmallest: "".concat(spacing(0, 0.25, 0.25, 0), " ").concat(shadowColor),
    shadowSmall: "".concat(spacing(0, 0.5, 0.5, 0), " ").concat(shadowColor),
    shadowMedium: "".concat(spacing(0, 1, 1, 0), " ").concat(shadowColor),
    shadowLarge: "".concat(spacing(0, 2, 2, 0), " ").concat(shadowColor),
    shadowLargest: "".concat(spacing(0, 3, 3, 0), " ").concat(shadowColor)
  };
}; // All the same on purpose for legacy compatibility

export var legacyShadows = function legacyShadows(shadowColor) {
  return {
    shadowSmallest: "0 10px ".concat(shadowColor),
    shadowSmall: "0 10px ".concat(shadowColor),
    shadowMedium: "0 10px ".concat(shadowColor),
    shadowLarge: "0 10px ".concat(shadowColor),
    shadowLargest: "0 10px ".concat(shadowColor)
  };
};