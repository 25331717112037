"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _default = function _default(singleFn, models) {
  return models.map(singleFn).join('\n');
};

exports["default"] = _default;