import { light } from '@foundation-lib/ui'

const colors = {
  tan: '#f2f0ec',
  darkGray: '#191919',
}

const theme = {
  ...light,
  lightBg: colors.tan,
  darkBg: colors.darkGray,
  primary: colors.darkGray,
  primaryHover: '#444444',
  primaryReverse: colors.tan,
  primaryReverseHover: '#e1dfdb',
  body: '#6f5c37',
  secondary: '#6f5c37',
  secondaryReverse: '#6f5c37',
  secondaryReverseHover: '#f9f8f6',
}

export default theme
