import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3;

import styled, { css } from 'styled-components';
import Button from './Button';
import { legacySwappableCss } from '../../theme'; // LEGACY theme

var legacyStyles = css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background-color: ", ";\n  color: ", ";\n"])), function (p) {
  return p.theme.primary;
}, function (p) {
  return p.theme.grey2;
}); // END LEGACY theme

export var StyledCurrentPageButton = styled(Button)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", "\n"])), legacySwappableCss(legacyStyles, css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n      background-color: ", ";\n      color: ", ";\n    "])), function (p) {
  return p.theme.relativeBlack;
}, function (p) {
  return p.theme.relativeWhite;
})));
export default StyledCurrentPageButton;