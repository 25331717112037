"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var send = function send() {
  var _console;

  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }

  (_console = console).log.apply(_console, ['gtm'].concat(args));

  if (typeof window !== "undefined") {
    var _window$dataLayer;

    (_window$dataLayer = window.dataLayer).push.apply(_window$dataLayer, args);
  }
};

var _default = send;
exports["default"] = _default;