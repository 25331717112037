"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  name: 'personAddress',
  fields: {
    city: 'NonEmptyString!',
    country: 'NonEmptyString!',
    line1: 'NonEmptyString!',
    line2: 'String',
    name: 'PersonName!',
    state: 'NonEmptyString!',
    zip: 'NonEmptyString!'
  }
};
exports["default"] = _default;