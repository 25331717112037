"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _fbpixel = _interopRequireDefault(require("../../../../fbpixel"));

var _decodePurchase = _interopRequireDefault(require("../../../events/decode/decodePurchase"));

var mapItem = function mapItem(_ref) {
  var id = _ref.id,
      quantity = _ref.quantity;
  return {
    id: id,
    quantity: quantity
  };
};

var purchase = function purchase() {
  return _fbpixel["default"].purchase(_decodePurchase["default"].apply(void 0, arguments));
};

var _default = purchase;
exports["default"] = _default;