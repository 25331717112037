"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var createCancellable = function createCancellable() {
  var cancel;
  var token = {
    promise: new Promise(function (resolve) {
      // eslint-disable-next-line fp/no-mutation
      cancel = function cancel(message) {
        if (token.reason) return; // the reason property can be checked
        // synchronously to see if you're cancelled
        // eslint-disable-next-line fp/no-mutation

        token.reason = {
          toString: function toString() {
            return "Request was cancelled".concat(message ? ": ".concat(message) : '');
          },
          cancelled: true
        };
        resolve(token.reason);
      };
    }),
    throwIfRequested: function throwIfRequested() {
      if (token.reason) {
        throw token.reason;
      }
    }
  };
  return {
    token: token,
    cancel: cancel
  };
};

var _default = createCancellable;
exports["default"] = _default;