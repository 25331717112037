import { useEffect } from 'react';
import { useDropdownList } from '../../dropdown';
import { KEYCODES } from '../../shared';
export default (function (_ref) {
  var anchorRef = _ref.anchorRef,
      _ref$availableOptions = _ref.availableOptions,
      availableOptions = _ref$availableOptions === void 0 ? [] : _ref$availableOptions,
      imperativeResults = _ref.results,
      _ref$onSelect = _ref.onSelect,
      onSelect = _ref$onSelect === void 0 ? function (opt) {
    return opt;
  } : _ref$onSelect,
      _ref$typed = _ref.typed,
      typed = _ref$typed === void 0 ? '' : _ref$typed,
      value = _ref.value;
  var results = imperativeResults || (typed ? availableOptions.filter(function (_ref2) {
    var label = _ref2.label;
    return label.toLowerCase().indexOf(typed) > -1;
  }) : []);

  var _useDropdownList = useDropdownList({
    anchorRef: anchorRef,
    items: results,
    onSelect: onSelect,
    value: value
  }),
      setDropdownShown = _useDropdownList.setDropdownShown,
      onKeyDown = _useDropdownList.onKeyDown,
      dropdownShown = _useDropdownList.dropdownShown,
      getItemProps = _useDropdownList.getItemProps,
      listProps = _useDropdownList.listProps,
      activeOption = _useDropdownList.activeOption,
      setActiveOptionNoFocus = _useDropdownList.setActiveOptionNoFocus,
      selectActiveOption = _useDropdownList.selectActiveOption;

  var handleKeyDown = function handleKeyDown(e) {
    if (![KEYCODES.ENTER, KEYCODES.UP_ARROW, KEYCODES.TAB, KEYCODES.DOWN_ARROW].includes(e.keyCode)) {
      setDropdownShown(true);
    }

    if (e.keyCode === KEYCODES.TAB) selectActiveOption();
    if (e.keyCode !== KEYCODES.SPACE) onKeyDown(e);
  };

  var primarySuggestedOptionIdx = results.findIndex(function (_ref3) {
    var label = _ref3.label;
    return label.toLowerCase().indexOf(typed.toLowerCase()) === 0;
  }) || 0;
  var suggestedOptionIdx = primarySuggestedOptionIdx > -1 ? primarySuggestedOptionIdx : 0;
  useEffect(function () {
    if (suggestedOptionIdx > -1 && suggestedOptionIdx !== activeOption) setActiveOptionNoFocus(suggestedOptionIdx); // We don't want this changing when the activeOption changes and resetting
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestedOptionIdx]);
  return {
    onKeyDown: handleKeyDown,
    results: results,
    resultsShown: results && results.length > 0 && dropdownShown,
    getItemProps: getItemProps,
    listProps: listProps,
    suggestedOption: results[suggestedOptionIdx]
  };
});