// We just need the array indices. The contents don't matter here
var arrayOfEmptyiness = function arrayOfEmptyiness(_ref) {
  var from = _ref.from,
      to = _ref.to;
  return Array(Math.floor(to - from) + 1);
}; // Build number ranges like we're some kind of Rubyist


export var range = function range(_ref2) {
  var from = _ref2.from,
      to = _ref2.to;

  if (to < from) {
    throw new RangeError('Arguments must be an integer range');
  }

  return Array.from(arrayOfEmptyiness({
    from: from,
    to: to
  })).map(function (_value, index) {
    return from + index;
  });
}; // Combine Min and Max into one method

export var limit = function limit(value, _ref3) {
  var min = _ref3.min,
      max = _ref3.max;

  if (max < min) {
    throw new RangeError('Max must be equal to or greater than min');
  }

  return Math.min(Math.max(value, min), max);
};