import React from 'react'
import { MarginOffset } from '@foundation-lib/ui'
import {
  StripeCardNumber,
  StripeCardExpiry,
  StripeCardCVC,
} from '@foundation-lib/formik-form'

export default () => (
  <>
    <h2>Payment info</h2>
    <MarginOffset>
      <StripeCardNumber width="full" />
      <StripeCardExpiry width="full" />
      <StripeCardCVC width="full" />
    </MarginOffset>
  </>
)
