"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _default = function _default(type) {
  var singularType = type.match(/\[?([A-Za-z0-9]*)\]?/)[1];
  return ['ID', 'Int', 'Float', 'String', 'Boolean', 'NonEmptyString', 'NonZeroFloat', 'Currency'].includes(singularType) ? type : type.replace(singularType, "".concat(singularType, "Input"));
};

exports["default"] = _default;