import React from 'react'
import { useMedia } from 'react-use'
import { useField, SubmitButton } from '@foundation-lib/formik-form'
import styled from 'styled-components'
import OrderAddresses from './OrderAddresses'
import CreditCard from './CreditCard'
import Referral from './Referral'
import { Cart } from '../../cart'

const MobileContainer = styled.div`
  padding: 20px 15px 0px 15px;
`

export default ({ errors }) => {
  const isMobile = useMedia('(max-width: 800px)')
  const [{ value: shippingAddress }] = useField('addresses.shipping')
  const [{ value: shippingService }] = useField('shippingService')

  const contents = (
    <>
      <h1>Checkout</h1>

      <OrderAddresses />
      <Referral />
      <h2>Order summary</h2>
      <Cart calculateShipping {...{ shippingAddress, shippingService }} />
      <CreditCard />
      <br />
      {errors?.length > 0 && (
        <div style={{ color: 'red', fontWeight: 'bold' }}>
          <br />
          There was an error with your submission. Please try again later.
          <ul>
            {errors.map(({ message }) => (
              <li>{message}</li>
            ))}
          </ul>
        </div>
      )}
      <br />
      <SubmitButton />
    </>
  )

  return isMobile ? <MobileContainer>{contents}</MobileContainer> : contents
}
