"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  name: 'download',
  fields: {
    key: 'String',
    url: 'String'
  }
};
exports["default"] = _default;