import { useEffect, useRef } from 'react';

var useFocus = function useFocus(itemRef, _ref) {
  var shouldFocus = _ref.shouldFocus,
      _ref$returnFocusOnLea = _ref.returnFocusOnLeave,
      returnFocusOnLeave = _ref$returnFocusOnLea === void 0 ? false : _ref$returnFocusOnLea;
  var prevFocusedElRef = useRef();
  useEffect(function () {
    if (shouldFocus && itemRef.current) {
      // eslint-disable-next-line fp/no-mutation
      prevFocusedElRef.current = typeof document !== "undefined" && document.activeElement;
      itemRef.current.focus();
    }

    return function () {
      var _prevFocusedElRef$cur, _prevFocusedElRef$cur2;

      return returnFocusOnLeave && (prevFocusedElRef === null || prevFocusedElRef === void 0 ? void 0 : (_prevFocusedElRef$cur = prevFocusedElRef.current) === null || _prevFocusedElRef$cur === void 0 ? void 0 : (_prevFocusedElRef$cur2 = _prevFocusedElRef$cur.focus) === null || _prevFocusedElRef$cur2 === void 0 ? void 0 : _prevFocusedElRef$cur2.call(_prevFocusedElRef$cur));
    };
  }, [shouldFocus, itemRef, prevFocusedElRef, returnFocusOnLeave]);
};

export default useFocus;