import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { ItemType } from '../../types';

var isNextInSequenceItem = function isNextInSequenceItem(_ref) {
  var prevItem = _ref.prevItem,
      currentItem = _ref.currentItem;
  return prevItem && currentItem.pageNumber !== prevItem.pageNumber + 1;
};

var needsSeparatorItem = function needsSeparatorItem(_ref2) {
  var prevItem = _ref2.prevItem,
      currentItem = _ref2.currentItem;
  var isNextInSequence = isNextInSequenceItem({
    prevItem: prevItem,
    currentItem: currentItem
  });
  return prevItem && prevItem.type !== ItemType.Separator && isNextInSequence;
};

var separatedPageItems = function separatedPageItems(items) {
  return items.reduce(function (acc, currentItem) {
    var prevItem = acc.slice(-1)[0];
    var needsSeparator = needsSeparatorItem({
      prevItem: prevItem,
      currentItem: currentItem
    });
    var separator = needsSeparator ? [{
      type: ItemType.Separator,
      text: '...'
    }] : [];
    return [].concat(_toConsumableArray(acc), separator, [currentItem]);
  }, []);
};

export default separatedPageItems;