import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject;

import { css } from 'styled-components';
var inputWidth = css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: ", ";\n  @media (max-width: 400px) {\n    width: 100%;\n  }\n"])), function (p) {
  return {
    small: '100px',
    medium: '275px',
    large: '325px',
    full: '100%'
  }[p.width || 'medium'];
});
export default inputWidth;