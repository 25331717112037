"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "fbpixel", {
  enumerable: true,
  get: function get() {
    return _fbpixel["default"];
  }
});
exports["default"] = void 0;

var _purchase = _interopRequireDefault(require("./events/purchase"));

var _fbpixel = _interopRequireDefault(require("./providers/fbpixel"));

var gtm = {
  purchase: _purchase["default"]
};
var _default = gtm;
exports["default"] = _default;