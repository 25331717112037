import { object, string } from 'yup'

const AddressSchema = object({
  name: object({
    first: string().label('First name').required(),
    last: string().label('Last name').required(),
  }),
  email: string().label('Email').required(),
  line1: string().label('Address line 1').required(),
  line2: string().label('Address line2'),
  country: string().label('Country').required(),
  state: string().label('State').required(),
  city: string().label('City').required(),
  zip: string().label('Zip').required(),
})

export default AddressSchema
