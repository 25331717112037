import { useEffect } from 'react';

var useInertOther = function useInertOther(ref) {
  useEffect(function () {
    if (typeof document !== "undefined") {
      document.body.childNodes.forEach(function (el) {
        var _setAttribute, _ref;

        return !el.contains(ref.current) && (el === null || el === void 0 ? void 0 : (_setAttribute = (_ref = el).setAttribute) === null || _setAttribute === void 0 ? void 0 : _setAttribute.call(_ref, 'inert', 'true'));
      });
      return function () {
        return document.body.childNodes.forEach(function (el) {
          var _removeAttribute, _ref2;

          return el === null || el === void 0 ? void 0 : (_removeAttribute = (_ref2 = el).removeAttribute) === null || _removeAttribute === void 0 ? void 0 : _removeAttribute.call(_ref2, 'inert');
        });
      };
    }
  }, [ref]);
};

export default useInertOther;