import React from 'react'
import { FormWrapper, Form, useFormContext } from '@foundation-lib/formik-form'
import * as Yup from 'yup'
import { Stripe } from '@foundation-lib/stripe'
import CheckoutFormContents from './CheckoutFormContents'
import CheckoutSuccess from './CheckoutSuccess'
import useCreatePurchase from '../utils/useCreatePurchase'
import { useAllCartData } from '../../cart'
import AddressSchema from '../data/schema/AddressSchema'

const CheckoutForm = ({ createPurchaseReq }) => {
  const { status, err, start, response, reset } = createPurchaseReq
  const { values, submitForm, validateForm } = useFormContext()
  const cartData = useAllCartData({
    shippingAddress: values.addresses?.shipping,
    shippingService: values.shippingService,
  })

  const serverErrors =
    (err?.graphQLErrors?.length && err?.graphQLErrors) ||
    (err?.message && [new Error('An unkown error has occurred')])

  return !status.isLoading && status.hasFinished && response?.id ? (
    <CheckoutSuccess
      onReset={reset}
      response={response}
      purchaseId={response?.createPurchase?.id}
    />
  ) : (
    <Form
      onSubmit={async e => {
        e.preventDefault()
        const isValid = Object.keys(await validateForm()) < 1
        await submitForm()
        if (isValid) start(values, cartData)
      }}
    >
      <CheckoutFormContents errors={serverErrors} />
    </Form>
  )
}

const getCcValidationRule = label =>
  Yup.bool().oneOf([true], `${label} invalid`).required()

const CheckoutFormWithData = () => {
  const createPurchaseReq = useCreatePurchase()

  return (
    <FormWrapper
      as="span"
      onSubmit={() => {}}
      initialValues={{
        useBillingAddressForShipping: true,
        addresses: {
          billing: {},
          shipping: {},
        },
      }}
      isSubmitting={createPurchaseReq.status.isLoading}
      validationSchema={Yup.object({
        addresses: Yup.object({
          billing: AddressSchema,
          shipping: AddressSchema,
        }),
        stripeCardNumberComplete: getCcValidationRule('Card number'),
        stripeCardExpiryComplete: getCcValidationRule('Expiration date'),
        stripeCardCvcComplete: getCcValidationRule('CVV'),
        shippingService: Yup.string().label('Shipping method').required(),
      })}
    >
      <CheckoutForm createPurchaseReq={createPurchaseReq} />
    </FormWrapper>
  )
}

const CheckoutFormContainer = ({ stripeApiKey }) => (
  <Stripe apiKey={stripeApiKey}>
    <CheckoutFormWithData />
  </Stripe>
)

export default CheckoutFormContainer
