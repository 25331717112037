import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { ItemType } from '../../types';
import separatedPageItems from './separated-page-items';
import visiblePageNumbers from './visible-page-numbers';
import { limit } from './ranges';

var pageNumberItems = function pageNumberItems(pageNumbers) {
  return pageNumbers.map(function (pageNumber) {
    return {
      type: ItemType.PageNumber,
      pageNumber: pageNumber,
      text: "".concat(pageNumber)
    };
  });
};

var pagePositions = function pagePositions(page, count) {
  var prevPage = limit(page - 1, {
    min: 1,
    max: Math.max(1, count - 1)
  });
  var nextPage = limit(page + 1, {
    min: Math.min(count, 2),
    max: count
  });
  return {
    prevPage: prevPage,
    nextPage: nextPage
  };
};

var withPrevAndNextLinks = function withPrevAndNextLinks(items, page, count) {
  var _pagePositions = pagePositions(page, count),
      prevPage = _pagePositions.prevPage,
      nextPage = _pagePositions.nextPage;

  return [{
    type: ItemType.PrevPage,
    pageNumber: prevPage,
    text: '<'
  }].concat(_toConsumableArray(items), [{
    type: ItemType.NextPage,
    pageNumber: nextPage,
    text: '>'
  }]);
};

var pageItems = function pageItems(page, options) {
  var pageNumbers = visiblePageNumbers(page, options);
  var items = pageNumberItems(pageNumbers);
  var separatedItems = separatedPageItems(items);
  var count = options.count;
  return withPrevAndNextLinks(separatedItems, page, count);
};

export default pageItems;