"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _graphql = require("graphql");

var _language = require("graphql/language");

var _default = new _graphql.GraphQLScalarType({
  name: 'NonEmptyString',
  description: 'Date custom scalar type',
  parseValue: function parseValue(value) {
    return value;
  },
  serialize: function serialize(value) {
    return value;
  },
  parseLiteral: function parseLiteral(ast) {
    if (ast.kind === _language.Kind.STRING) {
      return ast.value || undefined;
    }

    return undefined;
  }
});

exports["default"] = _default;