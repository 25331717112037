import React from 'react'
import {
  TextInput,
  SelectInput,
  Option,
  useField,
} from '@foundation-lib/formik-form'

export default () => {
  const [{ value: whoToldYouAboutUs }] = useField('whoToldYouAboutUs')

  return (
    <>
      <SelectInput
        margin="normal"
        width="full"
        select
        label="Who told you about us?"
        name="whoToldYouAboutUs"
      >
        <Option value="word-of-mouth">Friend / acquaintance</Option>
        <Option value="facebook-ad">Facebook ad</Option>
        <Option value="google">Google</Option>
        <Option value="mission-to-myanmar">
          Mission to Myanmar / Miles to Myanmar
        </Option>
        <Option value="radio">Radio</Option>
        <Option value="tv">Television</Option>
        <Option value="other">Other</Option>
      </SelectInput>
      {whoToldYouAboutUs === 'other' && (
        <TextInput
          margin="normal"
          fullWidth
          label='Specify who else told you about "Myanmar Gold"'
          name="whoToldYouAboutUsOther"
        />
      )}
    </>
  )
}
