module.exports = [{
      plugin: require('../../../../node_modules/.pnpm/gatsby-plugin-google-tagmanager@2.11.0_gatsby@3.14.6/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WC7B35H","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../../../../node_modules/.pnpm/gatsby-plugin-manifest@2.12.1_gatsby@3.14.6_graphql@15.8.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Foundations of Grace Publishing","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d53f7c331f9019a81e7eab60e1012b60"},
    },{
      plugin: require('../../../../node_modules/.pnpm/gatsby-remark-images@3.11.1_gatsby-plugin-sharp@2.14.4_gatsby@3.14.6/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../../../node_modules/.pnpm/gatsby-plugin-material-ui@2.1.10_@material-ui+styles@4.11.4_gatsby@3.14.6/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
