import React from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import CheckoutForm from './features/checkout'
import { Cart, useAllCartData, useCartItemList } from './features/cart'
import SettingsContext from './SettingsContext'

export const SettingsProvider = ({ children, value, apolloClient }) => (
  <ApolloProvider client={apolloClient}>
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  </ApolloProvider>
)
export { CheckoutForm, Cart, useAllCartData, useCartItemList }
