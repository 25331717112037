import React from 'react'
import { capitalize } from 'lodash'
import { SelectInput, Option } from '@foundation-lib/ui'
import { SelectInput as ContextField } from '@foundation-lib/formik-form'

const carriers = {
  usps: 'USPS',
  ups: 'UPS',
}

const ShippingMethodSelector = ({
  options,
  enableFormContext = false,
  className,
  onChange,
  value,
  defaultValue,
  ...other
}) => {
  const Component = enableFormContext ? ContextField : SelectInput
  return (
    <Component
      {...other}
      InputLabelProps={{ shrink: true }}
      label="Shipping Method"
      select
      name="shippingService"
      className={className}
      required
      {...(!enableFormContext && { onChange, value })}
      defaultValue={defaultValue}
    >
      {options.map(opt => (
        <Option value={opt} key={opt}>
          {opt === 'none'
            ? 'No shipping necessary'
            : opt
                .split('_')
                .map(w => carriers[w] || capitalize(w))
                .join(' ')}
        </Option>
      ))}
    </Component>
  )
}

export default ShippingMethodSelector
