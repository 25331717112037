"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = require("lodash");

var _default = function _default(_ref, methods) {
  var _ref2;

  var name = _ref.name;
  var capName = (0, _lodash.upperFirst)(name);
  return _ref2 = {}, (0, _defineProperty2["default"])(_ref2, "create".concat(capName), function create() {
    var _methods$create;

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return methods === null || methods === void 0 ? void 0 : (_methods$create = methods['create']) === null || _methods$create === void 0 ? void 0 : _methods$create.call.apply(_methods$create, [methods].concat(args));
  }), (0, _defineProperty2["default"])(_ref2, "update".concat(capName), function update() {
    var _methods$update;

    for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    return methods === null || methods === void 0 ? void 0 : (_methods$update = methods['update']) === null || _methods$update === void 0 ? void 0 : _methods$update.call.apply(_methods$update, [methods].concat(args));
  }), _ref2;
};

exports["default"] = _default;