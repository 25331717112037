import React from 'react'
import styled from 'styled-components'
import { Icon } from '@foundation-lib/ui'
import { faSpinner } from '@foundation-lib/ui/icons/solid'
import StandardRow from './table/StandardRow'
import HeadingRow from './table/HeadingRow'
import { ItemsTable } from './table/parts'
import ShippingRow from './table/ShippingRow'
import TotalRow from './table/TotalRow'
import ShippingMethodSelector from './ShippingMethodSelector'
import useAllCartData from '../data/useAllCartData'

const ItemsContainer = styled.div`
  background: #eee;
  padding: 10px 5px;
`

const MyShippingMethodSelector = styled(ShippingMethodSelector)`
  width: 300px;
  flex: 1 1 auto;

  & + & {
    margin-left: 3.5%;
  }
`

const ShippingContainer = styled.div`
  margin-bottom: 30px;
  margin-top: 15px;
  display: flex;
`

const Cart = ({ calculateShipping, shippingAddress, shippingService }) => {
  const { total, shipping, items } = useAllCartData({
    shippingAddress,
    shippingService,
  })

  return (
    <>
      <HeadingRow />
      <ItemsTable>
        <ItemsContainer>
          {items.map(item => (item.price ? <StandardRow data={item} /> : null))}
          <ShippingRow
            {...shipping}
            data={{ price: shipping.cost }}
            cantComputeLabel={
              calculateShipping ? 'Enter address' : 'Calculated at checkout'
            }
          />
        </ItemsContainer>
        <TotalRow
          label="Total"
          data={{
            price: total,
          }}
        />
      </ItemsTable>
      {calculateShipping &&
        (() => {
          if (shipping.isLoading)
            return (
              <p>
                <Icon icon={faSpinner} rotation={90} spin />
              </p>
            )
          if (shipping.cantCompute)
            return <p>Please enter address to show shipping method options.</p>
          return (
            <ShippingContainer>
              <MyShippingMethodSelector
                enableFormContext={calculateShipping}
                margin="none"
                options={shipping.options}
                name="shippingService"
                defaultValue={shipping.service}
              />
            </ShippingContainer>
          )
        })()}
    </>
  )
}

export default Cart
