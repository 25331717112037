var changeInputValueByRef = function changeInputValueByRef() {
  var ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    current: undefined
  };
  var value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var input = ref.current;

  if (input && typeof window !== "undefined") {
    Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set.call(input, value);
    var event = new Event('input', {
      bubbles: true
    });
    input.dispatchEvent(event);
  }
};

export default changeInputValueByRef;