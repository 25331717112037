import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState } from 'react';

var useOptionallyControlledInput = function useOptionallyControlledInput() {
  var inputProps = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      uncontrolled = _ref.uncontrolled;

  var _useState = useState(''),
      _useState2 = _slicedToArray(_useState, 2),
      internalValue = _useState2[0],
      setInternalValue = _useState2[1];

  var value = inputProps.value === '' || inputProps.value ? inputProps.value : internalValue;

  var onChange = function onChange(e) {
    if (!uncontrolled) setInternalValue(e.target.value);
    if (inputProps.onChange) inputProps.onChange(e);
  };

  return uncontrolled ? {
    onChange: onChange
  } : {
    value: value,
    onChange: onChange
  };
};

export default useOptionallyControlledInput;