"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var useDebouncedValue = function useDebouncedValue(value, delay) {
  var _useState = (0, _react.useState)(value),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      debouncedValue = _useState2[0],
      setDebouncedValue = _useState2[1];

  var lastValueRef = (0, _react.createRef)();
  var timerRef = (0, _react.createRef)(); // eslint-disable-next-line fp/no-mutation

  lastValueRef.current = value;
  var isPendingUpdate = JSON.stringify(lastValueRef.current) !== JSON.stringify(debouncedValue);
  (0, _react.useEffect)(function () {
    // eslint-disable-next-line fp/no-mutation
    timerRef.current = setTimeout(function () {
      if (isPendingUpdate) setDebouncedValue(lastValueRef.current);
    }, delay);
    return function () {
      return clearTimeout(timerRef.current);
    };
  }, [value, isPendingUpdate, delay, lastValueRef, timerRef]);
  return [debouncedValue, isPendingUpdate];
};

var _default = useDebouncedValue;
exports["default"] = _default;