"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _graphql = require("graphql");

var _language = require("graphql/language");

var _lodash = require("lodash");

var _default = new _graphql.GraphQLScalarType({
  name: 'Currency',
  description: 'Date custom scalar type',
  parseValue: function parseValue(value) {
    return value;
  },
  serialize: function serialize(value) {
    return value;
  },
  parseLiteral: function parseLiteral(ast) {
    if (ast.kind === _language.Kind.INT || ast.kind === _language.Kind.FLOAT) {
      var _float = parseFloat(ast.value);

      if (_float) return Math.round(_float * 100) / 100;
    }

    return undefined;
  }
});

exports["default"] = _default;