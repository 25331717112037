export var KEYCODES = {
  ENTER: 13,
  UP_ARROW: 38,
  DOWN_ARROW: 40,
  SPACE: 32,
  ESC: 27,
  HOME: 37,
  END: 39,
  TAB: 9
};
export default KEYCODES;