import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client'
import fetch from 'isomorphic-fetch'
import config from '../config'

const httpLink = new HttpLink({
  uri: `${config.apiUrl}/graphql`,
  fetch,
})

export default new ApolloClient({
  cache: new InMemoryCache(),
  link: from([httpLink]),
})
