import styled from 'styled-components'

const MarginOffset = styled.div`
  margin: ${(p) =>
    ({
      none: '0px',
      medium: '-11px',
    }[p.margin || 'medium'])};
  padding: 1px;
`

export default MarginOffset
