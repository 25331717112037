import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState } from 'react';
import pageItems from './page-items';

var useNewPagination = function useNewPagination() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref$initialPage = _ref.initialPage,
      initialPage = _ref$initialPage === void 0 ? 1 : _ref$initialPage,
      _ref$count = _ref.count,
      count = _ref$count === void 0 ? 1 : _ref$count,
      _ref$siblingStretchLe = _ref.siblingStretchLength,
      siblingStretchLength = _ref$siblingStretchLe === void 0 ? 1 : _ref$siblingStretchLe,
      _ref$edgeStretchLengt = _ref.edgeStretchLength,
      edgeStretchLength = _ref$edgeStretchLengt === void 0 ? 0 : _ref$edgeStretchLengt;

  var options = {
    initialPage: initialPage,
    count: count,
    siblingStretchLength: siblingStretchLength,
    edgeStretchLength: edgeStretchLength
  };

  var _useState = useState(initialPage),
      _useState2 = _slicedToArray(_useState, 2),
      page = _useState2[0],
      setPage = _useState2[1];

  var _useState3 = useState(pageItems(page, options)),
      _useState4 = _slicedToArray(_useState3, 2),
      items = _useState4[0],
      setItems = _useState4[1];

  var boundedPage = function boundedPage(proposedPage) {
    return Math.min(Math.max(1, proposedPage), count);
  };

  var changePage = function changePage(newPage) {
    var p = boundedPage(newPage);
    setPage(p);
    var newItems = pageItems(p, options);
    setItems(newItems);
  };

  return {
    options: options,
    items: items,
    page: page,
    changePage: changePage
  };
};

export default useNewPagination;