"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.update = exports.create = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _lodash = require("lodash");

var _getInputTypeName = _interopRequireDefault(require("../../lib/getInputTypeName"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var makeVars = function makeVars(fields) {
  return Object.entries(fields).map(function (_ref) {
    var _ref2 = (0, _slicedToArray2["default"])(_ref, 2),
        name = _ref2[0],
        type = _ref2[1];

    return "$".concat(name, ": ").concat((0, _getInputTypeName["default"])(type));
  }).join('\n');
};

var makeArgs = function makeArgs(fields) {
  return Object.entries(fields).map(function (_ref3) {
    var _ref4 = (0, _slicedToArray2["default"])(_ref3, 2),
        name = _ref4[0],
        type = _ref4[1];

    return "".concat(name, ": $").concat(name);
  }).join('\n');
};

var makeMutation = function makeMutation(_ref5, operation) {
  var fields = _ref5.fields,
      name = _ref5.name;
  return function (requestedFields) {
    var capName = (0, _lodash.upperFirst)(name);
    var id = fields.id,
        otherFields = (0, _objectWithoutProperties2["default"])(fields, ["id"]);
    var mutationFields = operation === 'create' ? otherFields : _objectSpread({
      id: id
    }, otherFields);
    return "\n    mutation ".concat((0, _lodash.upperFirst)(operation)).concat(capName, "(\n      ").concat(makeVars(mutationFields), "\n    ) {\n      ").concat(operation).concat(capName, "(\n        ").concat(makeArgs(mutationFields), "\n      ) {\n        ").concat(requestedFields, "\n      }\n    }\n  ");
  };
};

var create = function create(Model) {
  return makeMutation(Model, 'create');
};

exports.create = create;

var update = function update(Model) {
  return makeMutation(Model, 'update');
};

exports.update = update;