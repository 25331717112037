"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getList = exports.getSingle = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _lodash = require("lodash");

var _pluralize = require("pluralize");

var _getInputTypeName = _interopRequireDefault(require("../../lib/getInputTypeName"));

var makeVars = function makeVars(fields) {
  return Object.entries(fields).map(function (_ref) {
    var _ref2 = (0, _slicedToArray2["default"])(_ref, 2),
        name = _ref2[0],
        type = _ref2[1];

    return "$".concat(name, ": ").concat((0, _getInputTypeName["default"])(type));
  }).join('\n');
};

var makeArgs = function makeArgs(fields) {
  return Object.entries(fields).map(function (_ref3) {
    var _ref4 = (0, _slicedToArray2["default"])(_ref3, 2),
        name = _ref4[0],
        type = _ref4[1];

    return "".concat(name, ": $").concat(name);
  }).join('\n');
};

var getSingle = function getSingle(_ref5) {
  var fields = _ref5.fields,
      name = _ref5.name;
  return function (requestedFields) {
    var capName = (0, _lodash.upperFirst)(name);
    return "\n    query Get".concat(capName, "(\n      ").concat(makeVars(fields), "\n    ) {\n      ").concat(name, "(\n        ").concat(makeArgs(fields), "\n      ) {\n        ").concat(requestedFields, "\n      }\n    }\n  ");
  };
};

exports.getSingle = getSingle;

var getList = function getList(_ref6) {
  var fields = _ref6.fields,
      name = _ref6.name,
      _ref6$queryFields = _ref6.queryFields,
      queryFields = _ref6$queryFields === void 0 ? fields : _ref6$queryFields;
  return function (requestedFields) {
    var pluralName = (0, _pluralize.plural)(name);
    var pluralCapName = (0, _lodash.upperFirst)(pluralName);
    return "\n    query Get".concat(pluralCapName, "(\n      ").concat(makeVars(queryFields), "\n    ) {\n      ").concat(pluralName, "(\n        ").concat(makeArgs(queryFields), "\n      ) {\n        ").concat(requestedFields, "\n      }\n    }\n  ");
  };
};

exports.getList = getList;