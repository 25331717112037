import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useRef } from 'react'; // @ts-ignore

import { debounce } from 'lodash';
var ranges = ['48,57', '65,90', '96,111', '186,192', '219,222'].map(function (r) {
  return r.split(',');
});

var useKeyboardSelect = function useKeyboardSelect(_ref) {
  var onKeyDown = _ref.onKeyDown,
      items = _ref.items,
      setSelected = _ref.setSelected;
  var typed = useRef('');
  var clearTyped = useRef(debounce(function () {
    // eslint-disable-next-line fp/no-mutation
    typed.current = '';
  }, 500)).current;
  return function (e) {
    var k = e.keyCode;
    var isValid = ranges.find(function (_ref2) {
      var _ref3 = _slicedToArray(_ref2, 2),
          l = _ref3[0],
          h = _ref3[1];

      return k >= l && k <= h;
    });

    if (isValid) {
      // eslint-disable-next-line fp/no-mutation
      typed.current = "".concat(typed.current).concat(String.fromCharCode(k));
      var foundIdx = items.findIndex(function (_ref4) {
        var label = _ref4.label;
        return label.toLowerCase().indexOf(typed.current.toLowerCase()) === 0;
      });
      if (foundIdx > -1) setSelected(foundIdx);
    }

    clearTyped();
    onKeyDown(e);
  };
};

export default useKeyboardSelect;