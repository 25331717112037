"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  name: 'purchase',
  fields: {
    id: 'ID!',
    missionGiftAmount: 'Float',
    printingGiftAmount: 'Float',
    // num_myanmar_gold_books: 'Int',
    items: '[PurchaseLineItem!]!',
    person: 'Person!',
    stripeToken: 'NonEmptyString!',
    subtotal: 'Currency!',
    total: 'Currency!',
    useBillingAddressForShipping: 'Boolean',
    whoToldYouAboutMyanmarGold: 'String',
    whoToldYouAboutMyanmarGoldOther: 'String',
    shippingCost: 'Currency!',
    shippingService: 'String!'
  }
};
exports["default"] = _default;