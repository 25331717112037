import { orderBy, pick, uniq } from 'lodash'
import { useQuery } from '@apollo/react-hooks'
import { useDebouncedValue } from '@foundation-lib/react-hooks'
import GET_SHIPPING_RATES from './getShippingRates'

export default ({ orderWeight, shippingAddress, selectedService }) => {
  const requiredAddrFields = ['country', 'state', 'zip']

  const [shippingCalcVars, isUpdatePending] = useDebouncedValue(
    {
      carrierCode: 'stamps_com',
      ...pick(shippingAddress, requiredAddrFields),
      weightOz: orderWeight,
    },
    700,
  )

  const cantCompute =
    !requiredAddrFields.every(k => shippingCalcVars[k]) ||
    shippingCalcVars.zip.length < 5

  const { data: queryData, loading, error } = useQuery(GET_SHIPPING_RATES, {
    variables: shippingCalcVars,
    skip: cantCompute || !orderWeight,
  })

  const shippingRates =
    (!isUpdatePending && !loading && !error && queryData?.shippingRates) || []

  const ratesSortedByCost = orderBy(shippingRates, 'shipmentCost')
  const findRateByCode = code =>
    ratesSortedByCost.find(({ serviceCode }) => serviceCode === code)

  const selectedRate = !orderWeight
    ? { serviceCode: 'none' }
    : findRateByCode(selectedService) ||
      findRateByCode('usps_media_mail') ||
      ratesSortedByCost[0] ||
      {}

  const shippingCost = orderWeight ? selectedRate.shipmentCost || null : 0

  const options = !orderWeight
    ? ['none']
    : uniq(shippingRates.map(({ serviceCode }) => serviceCode))

  return {
    options,
    cantCompute,
    isLoading: loading || isUpdatePending,
    service: selectedService || selectedRate?.serviceCode,
    cost: shippingCost,
  }
}
