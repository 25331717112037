import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var colors = {
  // Primaries
  teal: '#16688d',
  purple: '#4c3f77',
  blue: '#00c9f0',
  pink: '#f49fa4',
  // Shades
  darkTeal: '#0d3e54',
  darkPurple: '#2d2547',
  darkPink: '#a95f64',
  // Tints
  lightTeal: '#d0e0e8',
  lightPurple: '#dbd8e3',
  lightBlue: '#e6f9fd',
  lightPink: '#fcebec',
  lightDanger: '#f9dfe2',
  lightWarning: '#fff8d9',
  // Greyscale
  black: '#000000',
  storm: '#333333',
  charcoal: '#666666',
  steel: '#999999',
  stone: '#cccccc',
  smoke: '#eeeeee',
  fog: '#f9f9f9',
  white: '#ffffff',
  // Status
  info: '#16688d',
  danger: '#d72b3f',
  warning: '#ffcc00',
  success: '#29a979',
  // Use specific
  link: '#0000ee'
};
var lightColors = {
  primary: colors.teal,
  secondary: colors.purple,
  tertiary: colors.blue,
  quaternary: colors.pink,
  info: colors.info,
  danger: colors.danger,
  warning: colors.warning,
  success: colors.success,
  link: colors.link,
  text: colors.storm,
  grey1: colors.black,
  grey2: colors.storm,
  grey3: colors.charcoal,
  grey4: colors.steel,
  grey5: colors.stone,
  grey6: colors.smoke,
  grey7: colors.fog,
  grey8: colors.white,
  relativeBlack: colors.black,
  relativeWhite: colors.white,
  shadow: 'rgba(51, 51, 51, 0.12)' // smoke,

};
var CHANNEL_LENGTH = 2;
var COLOR_LEVEL_STEP = 8; // color levels step 8%

var HEX_BASE = 16; // Color mix function ported from sass

var mix = function mix(hexColorLeftWithHash, hexColorRightWithHash) {
  var weight = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 50.0;
  var mixedColor = '#';
  var hexColorLeft = hexColorLeftWithHash.substring(1);
  var hexColorRight = hexColorRightWithHash.substring(1);

  var decimalToHex = function decimalToHex(d) {
    return d.toString(HEX_BASE);
  };

  var hexToDecimal = function hexToDecimal(h) {
    return parseInt(h, HEX_BASE);
  }; // loop through each of the 3 hex channels: red, green, and blue
  // Mutable version is much more readable than immutable

  /* eslint-disable fp/no-mutation */


  var leftChannel;
  var rightChannel;
  var mixedChannel;

  for (var i = 0; i <= 5; i += CHANNEL_LENGTH) {
    leftChannel = hexToDecimal(hexColorLeft.substr(i, CHANNEL_LENGTH));
    rightChannel = hexToDecimal(hexColorRight.substr(i, CHANNEL_LENGTH)); // combine the current channel from each source color according to the
    // specified weight

    mixedChannel = decimalToHex(Math.floor(rightChannel + (leftChannel - rightChannel) * (weight / 100.0))); // each channel must be 2 digits for the final hex color

    while (mixedChannel.length < CHANNEL_LENGTH) {
      mixedChannel = "0".concat(mixedChannel);
    }

    mixedColor += mixedChannel;
  }
  /* eslint-enable fp/no-mutation */


  return mixedColor;
};

var colorLevel = function colorLevel(hexColor, level) {
  var base = level > 0 ? '#000000' : '#ffffff';
  var absLevel = Math.abs(level);
  return mix(base, hexColor, absLevel * COLOR_LEVEL_STEP);
};

var colorBase = {
  colorLevel: colorLevel,
  shade: function shade(hexColor) {
    return colorLevel(hexColor, 5);
  },
  tint: function tint(hexColor) {
    return colorLevel(hexColor, -10);
  }
};
var fogpColors = {
  tan: '#f2f0ec',
  darkGray: '#191919'
};

var fogpTheme = _objectSpread(_objectSpread({}, light), {}, {
  lightBg: fogpColors.tan,
  darkBg: fogpColors.darkGray,
  primary: fogpColors.darkGray,
  primaryReverse: fogpColors.tan
});

export var light = _objectSpread(_objectSpread(_objectSpread({}, colorBase), lightColors), {}, {
  colorLevel: colorLevel
}, fogpTheme);
export var dark = _objectSpread(_objectSpread(_objectSpread({}, colorBase), lightColors), {}, {
  grey1: colors.white,
  grey2: colors.fog,
  grey3: colors.smoke,
  grey4: colors.stone,
  grey5: colors.steel,
  grey6: colors.charcoal,
  grey7: colors.storm,
  grey8: colors.black,
  text: colors.fog,
  relativeBlack: colors.white,
  relativeWhite: colors.storm,
  shadow: 'rgba(249, 249, 249, 0.50)'
});

var legacyColors = _objectSpread(_objectSpread({}, lightColors), {}, {
  primary: '#00c8f0',
  secondary: '#bb58d2',
  tertiary: '#666666',
  darkBlue: '#0088a3',
  success: '#68e37e',
  danger: '#ff4b47',
  text: '#666666',
  inputText: '#797979',
  shadow: '#ededed'
});

export var legacy = _objectSpread(_objectSpread({}, colorBase), legacyColors);